<template>
  <section
    :class="[
      'partner-cd__wrapper',
      'partner-cd__wrapper',
      {
        'course__detail--section': !isAuthorized,
        'not-found': getPageNotFound
      }
    ]"
  >
    <div class="course__bg--wrapper">
      <div
        :class="[
          { 'container cd__wrapper fixed-container': !isAuthorized },
          { 'h-100': isAuthorized }
        ]"
      >
        <div
          :class="['cd__course-detail', { 'cd-top-section': !isAuthorized }]"
        >
          <div :class="{ 'd-none d-sm-block': isAuthorized }">
            <LxpBreadcrumb
              :items="setBreadcrumbsPath"
              @click="navigateToLink"
            />
          </div>
          <div class="cd-main__wrapper">
            <div
              class="cd-main__container"
              v-if="!isLoading && Object.keys(getSelectedCourse).length != 0"
            >
              <b-row class="m-0">
                <b-col cols="12" sm="12" md="12" lg="9" class="p-0">
                  <div class="cd__title--style" v-b-visible="visibleHandler">
                    {{ getSelectedCourse.name }}
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="[
        { 'container cd__wrapper fixed-container': !isAuthorized },
        { 'h-100': isAuthorized }
      ]"
      :id="isAuthorized ? 'qa-course-detail-view' : ''"
    >
      <div
        :class="['cd__course-detail', { 'cd-bottom-section': !isAuthorized }]"
      >
        <div class="cd-main__wrapper">
          <Shimmer v-if="isLoading"></Shimmer>
          <div
            class="cd-main__container"
            v-else-if="!isLoading && Object.keys(getSelectedCourse).length != 0"
          >
            <div
              :class="{
                'd-flex': isAuthorized,
                'cd-video-section': !isAuthorized
              }"
            >
              <div
                :class="{
                  'cd-main_course-content': isAuthorized,
                  row: !isAuthorized
                }"
              >
                <div
                  :class="{
                    'mb-3 col-md-12 col-lg-9 left-panel': !isAuthorized
                  }"
                >
                  <div class="cd__prg-overview__banner">
                    <b-img
                      fluid-grow
                      center
                      class="cd__overview--style"
                      :src="
                        placeholderImage || getSelectedCourse.course_image_url
                      "
                      :alt="getSelectedCourse.name"
                      @error="handleError"
                    ></b-img>
                  </div>
                  <div
                    class="d-flex justify-content-between self-paced-calender-icons border-bottom d-lg-none"
                  >
                    <div class="text-center">
                      <div>
                        <img
                          src="@/assets/images/courseware/self-paced-blue.svg"
                          alt="self-paced"
                        />
                      </div>
                      <div class="title">
                        {{ pacingText }}
                      </div>
                      <div class="description">
                        {{ $t("course_detail.hundred_percent_online") }}
                      </div>
                    </div>
                    <div class="text-center">
                      <div>
                        <img
                          src="@/assets/images/courseware/calendar-blue.svg"
                          alt="calendar"
                        />
                      </div>
                      <div class="title">
                        {{ $t("course_detail.starting") }}
                      </div>
                      <div class="description">
                        {{
                          getSelectedCourse.start | formatDate("MMM Do YYYY")
                        }}
                      </div>
                    </div>
                    <div class="text-center">
                      <div>
                        <img
                          src="@/assets/images/courseware/end-date-calendar.svg"
                          alt="calendar"
                        />
                      </div>
                      <div class="title">
                        {{ $t("course_detail.ending") }}
                      </div>
                      <div class="description">
                        {{ getSelectedCourse.end | formatDate("MMM Do YYYY") }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="cd-tabs-custom-styles"
                    :class="{ 'cd-body-section': !isAuthorized }"
                  >
                    <div class="cd-sec__des" v-if="getSelectedCourse.about">
                      <p v-for="(a, i) in aboutArr" :key="i">
                        {{ a }}
                      </p>
                    </div>
                  </div>

                  <div class="cd_org_details">
                    <b-row>
                      <b-col class="cd_org_card__header_l">
                        {{ $t("course.designed_and_developed_by") }}
                      </b-col>
                    </b-row>
                    <b-row class="cd_org-card">
                      <b-col class="cd_org-col" cols="12">
                        <div
                          :class="[
                            'ls-card__wrapper',
                            { 'ls-card__inactive': !showSummary }
                          ]"
                          @click="onSpaceClick(getSelectedCourse.partner)"
                        >
                          <div class="ls-card__left" v-if="showSummary">
                            <img
                              :src="getSelectedCourse.partner.logo"
                              alt="logo"
                              width="128"
                              height="72"
                            />
                          </div>
                          <div
                            :class="[
                              'ls-card__right',
                              { 'pl-0': !showSummary }
                            ]"
                          >
                            <h6>{{ getSelectedCourse.partner.name }}</h6>
                            <p>
                              {{ getSelectedCourse.partner.short_description }}
                            </p>
                            <div class="ls-card__footer" v-if="showSummary">
                              <div
                                class="ls-card__footer-view"
                                @click="onSpaceClick(getSelectedCourse.partner)"
                              >
                                <span>View More</span>
                                <img
                                  src="@/assets/images/module-list/arrow-right.svg"
                                  alt="edit"
                                />
                              </div>
                            </div>
                          </div></div
                      ></b-col>
                    </b-row>
                  </div>

                  <div class="pt-2">
                    <b-row>
                      <b-col cols="12" sm="12" md="12" lg="12">
                        <div
                          class="more-learning-programs"
                          v-if="recommendedCourses.length"
                        >
                          <DashboardHeading
                            iconFileName="more-learning-programs-yellow-icon.svg"
                          >
                            {{
                              $t(
                                "course_discovery.heading.more_learning_programmes"
                              )
                            }}
                          </DashboardHeading>
                          <div class="mt-4">
                            <RecommendedCourses
                              :isLoading="isLoading"
                              :courses="recommendedCourses"
                              @click="onCard"
                            />
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div
                  v-if="!isAuthorized"
                  class="mb-3 col-md-3 text-right d-none d-lg-block right-panel"
                >
                  <PartnerActions
                    :isTitleDisplay="isTitleDisplay"
                    :getSelectedCourse="getSelectedCourse"
                    :language="language"
                    :allConfig="allConfig"
                    :hasFutureStartDate="hasFutureStartDate"
                  />
                </div>
              </div>
              <!-- Actions component -->
              <div
                v-if="isAuthorized"
                class="cd-main_course-actions text-right d-none d-lg-block"
              >
                <PartnerActions
                  :isTitleDisplay="isTitleDisplay"
                  :getSelectedCourse="getSelectedCourse"
                  :language="language"
                  :allConfig="allConfig"
                  :hasFutureStartDate="hasFutureStartDate"
                />
              </div>
            </div>
          </div>
          <div
            class="d-flex align-items-center justify-content-center h-100"
            v-else
          >
            <PageNotFound
              :description="$t('error.page_not_found.subtitle')"
              :exploreButton="false"
            />
          </div>
        </div>
        <div
          v-if="isAuthorized"
          class="cd-fixed-footer-section d-block d-lg-none"
        >
          <PartnerActions
            :isTitleDisplay="isTitleDisplay"
            :getSelectedCourse="getSelectedCourse"
            :language="language"
            :allConfig="allConfig"
            :hasFutureStartDate="hasFutureStartDate"
          />
        </div>
        <div
          v-else
          id="cd-footer-section"
          class="cd-fixed-footer-section d-block d-lg-none"
        >
          <div class="footer-container">
            <LxpButton
              size="default"
              block
              @click="onLearnMore"
              :class="{ 'gradient-bg': !authenticated }"
            >
              {{ $t("partner.button.learn_more") }}
              <img
                src="@/assets/images/course-details/learn-more.svg"
                alt="learn-more"
              />
            </LxpButton>
            <div class="cd__register-text">
              <a
                class="cd__register--link"
                href="javascript:;"
                @click="onSpaceClick(getSelectedCourse.partner)"
              >
                {{ $t("general.register") }}
              </a>
              <span class="cd__start-learning">{{
                $t("general.to_start_learning")
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { LxpBreadcrumb, LxpButton } from "didactica";
import moment from "moment";
import RecommendedCourses from "@/components/Dashboard/RecommendedCourses.vue";
// wrapper components
import Shimmer from "@/components/WrapperComponents/Shimmer.vue";
// child components
import PartnerActions from "@/components/CourseDetail/PartnerActions.vue";
//mixins
import learningSpaceMixin from "@/components/CourseDetail/mixins/learningSpace";
import redirectToLearningSpace from "@/core/mixins/redirectToLearningSpace";
import PageNotFound from "./PageNotFound.vue";
export default {
  components: {
    Shimmer,
    PartnerActions,
    RecommendedCourses,
    LxpButton,
    LxpBreadcrumb,
    PageNotFound
  },
  mixins: [redirectToLearningSpace, learningSpaceMixin],
  props: {
    isAuthorized: Boolean
  },
  data() {
    return {
      breadcrumbs: [
        {
          icon: require("@/assets/images/courseware/learning-interest.svg"),
          text: this.$i18n.t("general.menu.discovery"),
          to: { name: "course-discovery" }
        },
        {
          icon: null,
          text: "",
          active: true
        }
      ],
      isLoading: false,
      showingFullText: false,
      isTitleDisplay: false,
      placeholderImage: "",
      recommendedCourses: []
    };
  },
  computed: {
    ...mapGetters([
      "getAuthProfile",
      "getSelectedCourse",
      "allConfig",
      "language",
      "getPageNotFound"
    ]),
    hasFutureStartDate() {
      return moment(this.getSelectedCourse.current_server_time).isBefore(
        moment(this.getSelectedCourse.start)
      );
    },
    setBreadcrumbsPath() {
      var courseTitle = "";

      if (
        this.getSelectedCourse !== undefined &&
        this.getSelectedCourse.name !== undefined
      ) {
        let label = this.getSelectedCourse.name;
        if (label.length <= 30) {
          courseTitle = label;
        } else {
          courseTitle = label.slice(0, 30) + " ...";
        }
      }
      if (!this.isAuthorized) {
        return [
          {
            icon: null,
            text: this.$i18n.t("general.menu.home"),
            to: { name: "Home" }
          },
          {
            icon: null,
            text: this.$i18n.t("general.menu.discovery"),
            to: { name: "discovery" }
          },
          {
            icon: null,
            text: courseTitle,
            active: true
          }
        ];
      } else if (this.$route.query.from === "learning-programmes") {
        return [
          {
            icon: require("@/assets/images/courseware/school-grey.svg"),
            text: this.$i18n.t("general.menu.my_learning")
          },
          {
            icon: null,
            text: this.$i18n.t("general.menu.my_programmes"),
            to: { name: "my-programmes" }
          },
          {
            icon: null,
            text: courseTitle,
            active: true
          }
        ];
      } else if (this.$route.query.from === "home-courses") {
        return [
          {
            icon: require("@/assets/images/courseware/dashboard.svg"),
            text: this.$i18n.t("general.menu.home"),
            to: { name: "Dashboard" }
          },
          {
            icon: null,
            text: courseTitle,
            active: true
          }
        ];
      } else if (this.$route.query.from === "discovery") {
        return [
          {
            icon: require("@/assets/images/menu/library-discovery.svg"),
            text: this.$i18n.t("general.menu.discovery"),
            to: { name: "course-discovery" }
          },
          {
            icon: null,
            text: courseTitle,
            active: true
          }
        ];
      } else if (this.$route.query.from === "learning-space") {
        const courseOrg = this.$route.query.org.toUpperCase();
        return [
          {
            icon: require("@/assets/images/menu/library-discovery.svg"),
            text: this.$i18n.t("general.menu.discovery"),
            to: { name: "course-discovery" }
          },
          {
            text: courseOrg,
            to: { name: "LearningSpace", params: { org: courseOrg } },
            active: false
          },
          {
            icon: null,
            text: courseTitle,
            active: true
          }
        ];
      }
      return [
        {
          icon: require("@/assets/images/courseware/learning-interest.svg"),
          text: this.$i18n.t("general.menu.discovery"),
          to: { name: "course-discovery" }
        },
        {
          icon: null,
          text: courseTitle,
          active: true
        }
      ];
    },
    pacingText() {
      const pacing = this.getSelectedCourse.pacing;
      if (pacing === "self" || pacing === "instructor") {
        return this.$t(`data.pacing.${pacing}`);
      } else {
        return pacing;
      }
    },
    aboutArr() {
      return this.getSelectedCourse.about.split("\n").filter(a => a !== "");
    }
  },
  mounted() {
    if (this.authenticated) {
      if (this.isUserFluidLayoutLoaded()) {
        this.$nextTick(async () => {
          this.init();
        });
      }
    } else {
      this.$emit("update:layout", "main");
      this.init();
    }
  },
  methods: {
    init() {
      this.isLoading = true;
      this.getCourseDetail();
      this.getRandomCourse(3);
    },
    handleError() {
      if (window.innerWidth <= 480) {
        this.placeholderImage = require("@/assets/images/not-found/course-img-not-found-responsive.svg");
      } else {
        this.placeholderImage = require("@/assets/images/illustration/course-detail-illustration.svg");
      }
    },
    async getRandomCourse(count) {
      this.recommendedCourses = await this.$store.dispatch(
        "getRandomCourses",
        count
      );
    },

    getCourseDetail() {
      let dispatchMethod = this.authenticated
        ? "getPartnerCourseDetail"
        : "getAPublicPartnerCourse";
      this.$store
        .dispatch(dispatchMethod, {
          course_id: this.$route.params.id,
          version: "v1"
        })
        .then(() => {
          this.isLoading = false;
          this.getLearningSpaceSummary(
            this.getSelectedCourse.partner.org_short_name
          );
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    navigateToLink(item) {
      this.$router.push(item.to);
    },
    onCard(c) {
      this.$router.push({
        name: "Courses Detail",
        params: { id: c.id },
        query: { source: c.source }
      });
    },
    visibleHandler(isVisible) {
      if (isVisible) {
        this.isTitleDisplay = false;
      } else {
        this.isTitleDisplay = true;
      }
    },
    onLearnMore() {
      window.open(this.getSelectedCourse.course_url, "_blank");
    }
  }
};
</script>
<style lang="scss">
@import "../scss/course-details/_common.scss";
.partner-cd__wrapper {
  .cd-sec__des {
    p {
      @include body-regular;
      white-space: break-spaces;
      margin-bottom: 20px;
    }
  }
}
</style>
